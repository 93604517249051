import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {AddClientComponent} from '../dialogues/add-client/add-client.component';
import {MatDialog} from '@angular/material/dialog';
import {NewAppointmentComponent} from '../dialogues/new-appointment/new-appointment.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(public authentication: AuthService, public router: Router, private dialog: MatDialog) {

  }


  categories: any = [
    {action: 'calender', display: 'Calender', icon: 'event'},
    {action: 'appointments', display: 'Appointments', icon: 'view_list'},
    {action: 'clients', display: 'Clients', icon: 'people'},
    {action: 'reminder-logs', display: 'Reminder Logs', icon: 'contact_mail'},
  ];

  ngOnInit(): void {

  }

  async logoutUser() {
    await this.authentication.logout();
    await this.router.navigate(['login']);
  }

  addClient() {
    this.dialog.open(AddClientComponent, {
      width: '800px',
      data: {
        message: 'Are you sure want to add the vehicles to package',
        type: 'CONFIRM',
        title: 'Add Vehicles'
      },
    });
  }

  newAppointment() {
    this.dialog.open(NewAppointmentComponent, {
      width: '800px',
      data: new Date().toLocaleString(),
    });
  }
}
