import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-confirm-dialogue',
  templateUrl: './confirm-dialogue.component.html',
  styleUrls: ['./confirm-dialogue.component.scss']
})

export class ConfirmDialogueComponent implements OnInit {

  message = null;
  title = null;
  type = null;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.message = data.message;
    this.type = data.type;
    this.title = data.title;

  }

  onNoClick(confirm = true): void {
    console.error('confirm clicked', confirm);
    this.dialogRef.close(confirm);

  }

  ngOnInit(): void {


  }
}

