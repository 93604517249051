import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {Subject} from 'rxjs';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {takeUntil} from 'rxjs/operators';
import {NewAppointmentComponent} from '../../dialogues/new-appointment/new-appointment.component';
import {MatDialog} from '@angular/material/dialog';
import {AppointmentService} from '../../services/appointment.service';
import * as moment from 'moment';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit, OnDestroy {

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({event}: { event: CalendarEvent }): void => {
        console.log(event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({event}: { event: CalendarEvent }): void => {
        console.log(event);
        // this.events = this.events.filter((iEvent) => iEvent !== event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  oldDateObj = new Date();
  newDateObj = new Date().setTime(this.oldDateObj.getTime() + (60 * 60 * 1000));
  newDateObj2 = new Date().setTime(this.oldDateObj.getTime() + (90 * 60 * 1000));
  //
  // events: CalendarEvent[] = [
  //   // {
  //   //   start: new Date(),
  //   //   end: new Date(this.newDateObj),
  //   //   title: 'A full day event',
  //   //   actions: this.actions,
  //   //   allDay: false,
  //   //   resizable: {
  //   //     beforeStart: true,
  //   //     afterEnd: true,
  //   //   },
  //   //   draggable: true,
  //   // },
  //   // {
  //   //   start: new Date(),
  //   //   end: new Date(this.newDateObj2),
  //   //   title: 'Siv Sugaman',
  //   //   actions: this.actions,
  //   //   draggable: true,
  //   // }
  // ];

  events: CalendarEvent[] = [];

  daysInWeek = 7;
  private destroy$ = new Subject();

  weekStartsOn = DAYS_OF_WEEK.SUNDAY;
  weekends = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

  appointments = [];
  appointmentData = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    public appointmentService: AppointmentService
  ) {
    this.appointmentService.allAppointments().subscribe((ev) => {
      this.events = ev.map(event => {
        return {
          start: new Date(event.appointment_at),
          end: new Date(event.appointment_to),
          title: event.name + ' - ' + event.remark,
          actions: this.actions,
          draggable: false,
        };
      });
    });

  }

  ngOnInit(): void {
    const CALENDAR_RESPONSIVE = {
      small: {
        breakpoint: '(max-width: 576px)',
        daysInWeek: 2,
      },
      medium: {
        breakpoint: '(max-width: 768px)',
        daysInWeek: 3,
      },
      large: {
        breakpoint: '(max-width: 960px)',
        daysInWeek: 5,
      },
    };

    this.breakpointObserver
      .observe(
        Object.values(CALENDAR_RESPONSIVE).map(({breakpoint}) => breakpoint)
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
          ({breakpoint}) => !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.daysInWeek = foundBreakpoint.daysInWeek;
        } else {
          this.daysInWeek = 7;
        }
        this.cd.markForCheck();
      });


    console.log(this.events);

  }


  handleEvent(clicked: string, event: CalendarEvent<any>): void {

  }

  eventTimesChanged($event: CalendarEventTimesChangedEvent<any>): void {

  }

  hourSegmentClicked($event: { date: Date; sourceEvent: MouseEvent }): void {
    console.log($event);
    this.dialog.open(NewAppointmentComponent, {
      width: '800px',
      data: {
        time: $event.date
      },
    });
  }

  setView(view: CalendarView): void {
    this.view = view;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
  }
}
