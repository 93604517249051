<div class="bg-white border-t border-b">

  <div class="flex respon">

    <div class="flex-auto respon text-center px-4 py-2 border-r">
      <div class="flex justify-center items-center">

      </div>
    </div>

    <div
      class="respon text-center flex justify-between items-center text-white px-4 py-2  border-r cursor-pointer"
      style="color: #000;" (click)="setView(CalendarView.Month)"
      [class.bg-purple-100]="view === CalendarView.Month">
      Month
    </div>
    <div
      class="respon text-center flex justify-between items-center text-white px-4 py-2  border-r cursor-pointer"
      style="color: #000;" (click)="setView(CalendarView.Week)"
      [class.bg-purple-100]="view === CalendarView.Week">
      Week
    </div>
    <div
      class="respon text-center flex justify-between items-center text-white px-4 py-2  border-r cursor-pointer"
      style="color: #000;" (click)="setView(CalendarView.Day)"
      [class.bg-purple-100]="view === CalendarView.Day">
      Day
    </div>

    <div
      class=" respon text-center flex justify-between items-center text-white px-4 py-2 "
      style=" width:354px;color: #000;">

      <button mat-icon-button aria-label="Previous date"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate">
        <mat-icon>chevron_left</mat-icon>
      </button>

      &nbsp;
      &nbsp;
      <button
        mat-flat-button
        mwlCalendarToday
        [(viewDate)]="viewDate" matTooltip="Click for current date">
        {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
      </button>

      &nbsp;
      &nbsp;

      <button mat-icon-button aria-label="Next date"
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDate">
        <mat-icon>chevron_right</mat-icon>
      </button>

    </div>


  </div>
</div>
<div class="h-screen overflow" style="display: block; max-height: calc(100vh - 121px);overflow-y: scroll;">

  <div class="shadow-md" [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (dayClicked)="dayClicked($event.day); view = CalendarView.Day"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    ></mwl-calendar-month-view>

    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [weekStartsOn]="weekStartsOn"
      [daysInWeek]="daysInWeek"
      [weekendDays]="weekends"
      [dayStartHour]="6"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (hourSegmentClicked)="hourSegmentClicked($event)"
    ></mwl-calendar-week-view>

    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (hourSegmentClicked)="hourSegmentClicked($event)"
    ></mwl-calendar-day-view>
  </div>

  <!--<pre>{{events  | json}}</pre>-->
</div>


