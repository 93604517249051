import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {HelperClass} from '../helper';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private appointmentCollection: AngularFirestoreCollection<any>;
  private $allAppointments: BehaviorSubject<any[]> = new BehaviorSubject<[]>([]);
  public dateAppointments: BehaviorSubject<any[]> = new BehaviorSubject<[]>([]);
  private date$ = new Subject<string>();

  constructor(private afs: AngularFirestore,
              public afAuth: AngularFireAuth) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.appointmentCollection = this.afs.collection<any>('appointx-demo/' + user.uid + '/appointments');

        const queryObservable = this.date$.pipe(
          switchMap(date => {
              console.log('switchMap', date);
              return afs.collection<any>('appointx-demo/' + user.uid + '/appointments', ref => ref.where('date', '==', date)).valueChanges();
            }
          )
        );

        this.appointmentCollection.valueChanges().subscribe(data => this.$allAppointments.next(data));
        queryObservable.subscribe(queriedItems => {
          console.log('queryObservable', queriedItems);
          this.dateAppointments.next(queriedItems);
        });
      }
    });
  }

  public allAppointments(): Observable<any[]> {
    return this.$allAppointments.asObservable();
  }

  public async createNewAppointment(data): Promise<void> {
    const id = HelperClass.generatePushID();
    data.id = id;
    await this.appointmentCollection.doc(id).set(data);
  }

  appointmentDate(date: any): void {
    this.date$.next(date);
  }
}
