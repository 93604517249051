<mat-drawer-container class="example-container" autosize>

  <mat-drawer #drawer class="c20-sidenav" mode="side" [opened]="true">
    <div class="p-4">
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg" alt="Workflow">
    </div>

    <mat-divider></mat-divider>

    <mat-selection-list [multiple]="false">
      <!--      <mat-list-option routerLink="/app/" routerLinkActive="active-link">Dashboard</mat-list-option>-->

      <mat-list-option  routerLink="/app/dashboard" routerLinkActive="active-link">
        <mat-icon matListIcon>dashboard</mat-icon>
        Dashboard
      </mat-list-option>

      <mat-list-option *ngFor="let item of categories"  routerLink="/app/{{item.action}}" routerLinkActive="active-link">
        <mat-icon matListIcon>{{item.icon}}</mat-icon>
        {{item.display | titlecase}}
      </mat-list-option>
    </mat-selection-list>


  </mat-drawer>

  <div class="c20-sidenav-content">
    <div class="mat-elevation-z6 ">
      <mat-toolbar style="background: #fff;">
        <button (click)="drawer.toggle()" mat-icon-button style="margin: 8px;">
          <mat-icon>menu</mat-icon>
        </button>
        <!--Dashboard-->
        <span class="nav-spacer"></span>

        <button color="primary" [matMenuTriggerFor]="newMenu" mat-flat-button>
          New
        </button> &nbsp;
        <mat-menu #newMenu="matMenu">
          <div mat-menu-item (click)="newAppointment()">
            <mat-icon>playlist_add</mat-icon>
            Appointment
          </div>
          <div mat-menu-item (click)="addClient()">
            <mat-icon>person_add</mat-icon>
            Client Information
          </div>
        </mat-menu>
        <button [matMenuTriggerFor]="menu" mat-flat-button>
          Account
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item>
            <mat-icon>settings</mat-icon>
            Account Settings</a>
          <a mat-menu-item>
            <mat-icon>data_usage</mat-icon>
            Billing Information</a>
          <a mat-menu-item>
            <mat-icon>support_agent</mat-icon>
            Support</a>
          <mat-divider></mat-divider>
          <a (click)="logoutUser()" mat-menu-item>
            <mat-icon>lock</mat-icon>
            Logout</a>
        </mat-menu>

      </mat-toolbar>
    </div>
    <router-outlet></router-outlet>


  </div>

</mat-drawer-container>
