<div mat-dialog-content>
  <div class="border-b pb-2 font-bold text-lg">Appointment</div>

  <div *ngIf="errors">
    <div *ngFor="let err of errors" class="p-2 m-2 bg-red-200 rounded border border-red-700">
      <span class="text-red-700">{{err}}</span>
    </div>
  </div>
  <form #form>
    <div class="app-grid mt-2">
      <div class="w100">
        <mat-form-field class="w100 p-1" appearance="outline">
          <mat-label>Select Client</mat-label>
          <input type="text"
                 placeholder="Select Client"
                 aria-label="Number"
                 matInput
                 [formControl]="clientControl"
                 #clientName
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let client of filteredClients" [value]="client" (click)="selectedUser = client">
              {{client.firstname}}  {{client.lastname}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="w50">
        <mat-form-field appearance="outline" class="w100 respon p-1">
          <mat-label>Appointment Date</mat-label>
          <input required matInput [matDatepicker]="picker" name="dob" [(ngModel)]="appointmentTime" #apDate>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="w25">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Appointment start time</mat-label>
          <input matInput
                 placeholder="12hr format (default settings)"
                 aria-label="12hr format"
                 [value]="appointmentTime | date:'hh:mm aa'"
                 [ngxTimepicker]="appointFrom"
                 #appTimeAt
                 readonly
                 (click)="appointFrom.click()">

          <ngx-material-timepicker #appointFrom
                                   [defaultTime]="appointmentTime | date:'hh:mm aa'"></ngx-material-timepicker>
        </mat-form-field>
      </div>

      <div class="w25">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Appointment end time</mat-label>
          <input matInput
                 placeholder="12hr format (default settings)"
                 aria-label="12hr format"
                 [value]="appointmentTo | date:'hh:mm aa'"
                 [ngxTimepicker]="appointTo"
                 #appTimeTo
                 readonly
                 (click)="appointTo.click()"
                 readonly>
          <ngx-material-timepicker #appointTo [defaultTime]="appointmentTo | date:'hh:mm aa'"></ngx-material-timepicker>
        </mat-form-field>
      </div>

      <div class="w100">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Remark</mat-label>
          <textarea rows="1" matInput placeholder="Appointment for " name="address" #remark></textarea>
        </mat-form-field>
      </div>

    </div>
  </form>
</div>
<div mat-dialog-actions>
  <div class="flex flex-row-reverse w100">
    <button mat-flat-button color="primary"
            [disabled]="selectedUser == null || clientName.value == '' || apDate.value == '' ||  appTimeAt.value == '' ||  appTimeTo.value =='' || remark.value == ''"
            (click)="createAppointment(apDate.value, appTimeAt.value, appTimeTo.value, remark.value, form)">CREATE
      APPOINTMENT
    </button> &nbsp;
    <button mat-button (click)="onNoClick(false)">CANCEL</button>
  </div>
</div>
