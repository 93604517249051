import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './navigation.component';
import {MaterialComponentsModule} from '../common/material-components/material-components.module';


@NgModule({
  declarations: [NavigationComponent],
  imports: [
    CommonModule,
    MaterialComponentsModule
  ],
  exports: [NavigationComponent]
})
export class NavigationModule {
}
