import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {DashboardComponent} from './dashboard/dashboard.component';
import {MaterialComponentsModule} from './common/material-components/material-components.module';
import {NavigationModule} from './navigation/navigation.module';
import {CalenderComponent} from './components/calender/calender.component';
import {CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter, DateAdapter, MOMENT} from 'angular-calendar';
import * as moment from 'moment';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import {ConfirmDialogueComponent} from './dialogues/confirm-dialogue/confirm-dialogue.component';
import {AddClientComponent} from './dialogues/add-client/add-client.component';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {NewAppointmentComponent} from './dialogues/new-appointment/new-appointment.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

// tslint:disable-next-line:typedef
export function momentAdapterFactory() {
  return adapterFactory(moment);
}


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ConfirmDialogueComponent,
    AddClientComponent,
    CalenderComponent,
    NewAppointmentComponent
  ],
  entryComponents: [
    AddClientComponent,
    NewAppointmentComponent,
    ConfirmDialogueComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    NgxMaterialTimepickerModule,
    NavigationModule,
    NgxMatIntlTelInputModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: momentAdapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      }),
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    {
      provide: MOMENT,
      useValue: moment,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
