import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CalenderComponent} from './components/calender/calender.component';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {
    path: 'app', component: DashboardComponent, children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./components/dash/dash.module').then(m => m.DashModule)
      },
      {
        path: 'appointments',
        loadChildren: () => import('./components/appointment-view/appointment-view.module').then(m => m.AppointmentViewModule)
      },
      {
        path: 'calender',
        // loadChildren: () => import('./components/calender/calender.module').then(m => m.CalenderModule)
        component: CalenderComponent
      },
      {
        path: 'clients',
        loadChildren: () => import('./components/clients/clients.module').then(m => m.ClientsModule)
      },
      {path: '', redirectTo: '/app/dashboard', pathMatch: 'full'},
    ]
  },
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
