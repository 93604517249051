import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../services/auth.service';
import * as moment from 'moment';
import {HelperClass} from '../../helper';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  newClient = new Client('', '', '', '', '', '', '');

  constructor(
    private afs: AngularFirestore,
    public auth: AuthService,
    public dialogRef: MatDialogRef<AddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.newClient = new Client('', '', '', '', '', '', '');

  }

  onNoClick(confirm = true): void {
    console.error('confirm clicked', confirm);
    this.dialogRef.close(confirm);

  }

  ngOnInit(): void {
  }

  async create() {

    const id = HelperClass.generatePushID();
    await this.afs.collection('appointx-demo/' + this.auth.user.uid + '/clients').doc(id).set({
      id,
      firstname: this.newClient.firstname,
      lastname: this.newClient.lastname,
      email: this.newClient.email.toLowerCase(),
      mobileNo: this.newClient.mobileNo,
      gender: this.newClient.gender,
      dob: this.newClient.dob.format('DD/MM/YYYY'),
      address: this.newClient.address,
      created_at: moment(new Date()).toISOString()
    });
    this.newClient = new Client('', '', '', '', '', '', '');

    this.dialogRef.close(false);
  }

  async confirm() {
    if (
      this.newClient.firstname &&
      this.newClient.lastname &&
      this.newClient.email &&
      this.newClient.mobileNo &&
      this.newClient.gender &&
      this.newClient.dob
    ) {
      await this.create();
    } else {
      alert('please provide all the fields');
    }

  }
}

export class Client {
  firstname: string;
  lastname: string;
  email: string;
  mobileNo: string;
  gender: string;
  dob: any;
  address: string;

  constructor(firstname: string, lastname: string, email: string, mobileNo: string, gender: string, dob: string, address: string) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.mobileNo = mobileNo;
    this.gender = gender;
    this.dob = dob;
    this.address = address;
  }
}
