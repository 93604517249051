import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private clientsCollection: AngularFirestoreCollection<any>;
  private $allClients: BehaviorSubject<any[]> = new BehaviorSubject<[]>([]);

  constructor(private afs: AngularFirestore,
              public afAuth: AngularFireAuth) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.clientsCollection = this.afs.collection<any>('appointx-demo/' + user.uid + '/clients');
        this.clientsCollection.valueChanges().subscribe(data => this.$allClients.next(data));
      }
    });

  }

  public allClients(): Observable<any[]> {
    return this.$allClients.asObservable();
  }


}
