import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFireAuth} from '@angular/fire/auth';
import {ClientService} from '../../services/client.service';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';
import {HelperClass} from '../../helper';
import {AppointmentService} from '../../services/appointment.service';
import 'moment-timezone';


@Component({
  selector: 'app-new-appointment',
  templateUrl: './new-appointment.component.html',
  styleUrls: ['./new-appointment.component.scss']
})
export class NewAppointmentComponent implements OnInit {

  clientControl: FormControl = new FormControl();
  appointmentTime: any;
  appointmentTo: any;

  selectedUser = null;
  filteredClients = [];
  clientData = [];
  errors: any[];

  constructor(public clientService: ClientService,
              public appointService: AppointmentService,
              public afAuth: AngularFireAuth,
              public dialogRef: MatDialogRef<NewAppointmentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    if (this.data && this.data.time) {
      this.appointmentTime = this.data.time;
      this.appointmentTo = moment(this.data.time).add('30', 'minutes');
    }

    this.clientService.allClients().subscribe((clients) => {
      this.clientData = clients;
    });

  }

  ngOnInit(): void {
    this.clientControl.valueChanges.subscribe((key) => {
      if (typeof key === 'string') {
        this.filteredClients = this.clientData.filter(client => {
          return (client.firstname.toLowerCase().indexOf(key.toLowerCase()) > -1 ||
            client.lastname.toLowerCase().indexOf(key.toLowerCase()) > -1 ||
            client.mobileNo.toLowerCase().indexOf(key.toLowerCase()) > -1);
        });
      } else {
        console.log(key);
      }
    });
  }

  displayFn(user: any): string {
    if (user) {
      console.log('selected', user);
      this.selectedUser = user;
    } else {
      this.selectedUser = null;
    }
    return user && user.firstname ? user.firstname + ' ' + user.lastname : '';
  }


  onNoClick(confirm = true): void {
    console.error('confirm clicked', confirm);
    this.dialogRef.close(confirm);

  }


  async createAppointment(date: string, timeAt: string, timeTo: string, remark: string, form): Promise<void> {
    this.errors = [];
    try {


      // appointmentTime.set();
      // const appointmentTime = moment(new Date(date));

      const timeAt$ = HelperClass.AMPMto24(timeAt);
      const timeTo$ = HelperClass.AMPMto24(timeTo);

      const dateFromSplit = date.split('-');

      const appointmentFrom = moment();
      appointmentFrom.set('date', Number(dateFromSplit[0]));
      appointmentFrom.set('month', Number(dateFromSplit[1]) - 1);
      appointmentFrom.set('year', Number(dateFromSplit[2]));
      appointmentFrom.set('hour', Number(timeAt$.hour));
      appointmentFrom.set('minute', Number(timeAt$.minute));

      const appointmentTo = moment();
      appointmentTo.set('date', Number(dateFromSplit[0]));
      appointmentTo.set('month', Number(dateFromSplit[1]) - 1);
      appointmentTo.set('year', Number(dateFromSplit[2]));
      appointmentTo.set('hour', Number(timeTo$.hour));
      appointmentTo.set('minute', Number(timeTo$.minute));

      const user = JSON.parse(localStorage.getItem('user'));
      const aptmntData = {
        user_id: this.selectedUser.id,
        firstname: this.selectedUser.firstname,
        lastname: this.selectedUser.lastname,
        name: this.selectedUser.firstname + ' ' + this.selectedUser.lastname,
        appointment_at: appointmentFrom.local().format(),
        appointment_to: appointmentTo.local().format(),
        email: this.selectedUser.email,
        mobileNo: this.selectedUser.mobileNo,
        remark,
        created_at: moment().local().format(),
        status: 'NEW',
        date,
        mail_sent: Math.floor(Math.random() * 2) === 0,
        sms_sent: Math.floor(Math.random() * 2) === 0,
        created_by: user.uid
      };

      if (appointmentTo.local().unix() > appointmentFrom.local().unix()) {
        console.log(aptmntData);
        await this.appointService.createNewAppointment(aptmntData);
        this.selectedUser = null;
        form.reset();
        this.onNoClick(true);
      } else {
        this.errors.push('Please check the appointment end time');
      }

    } catch (e) {
      console.error(e);
    }

  }
}
