<div mat-dialog-content>
  <div class="border-b p-1 mb-2 font-bold text-lg">Add new client</div>
  <form>

    <div class="p-1 font-bold">Basic Details</div>

    <div class="app-grid">
      <div class="w50">
        <mat-form-field appearance="outline" class="w100  p-1">
          <mat-label>Firstname</mat-label>
          <input required matInput placeholder="Placeholder" name="firstname" [(ngModel)]="newClient.firstname">
          <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        </mat-form-field>
      </div>
      <div class="w50">
        <mat-form-field appearance="outline" class="w100  p-1">
          <mat-label>Lastname</mat-label>
          <input required matInput placeholder="Placeholder" name="lastname" [(ngModel)]="newClient.lastname">
          <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="app-grid">
      <div class="w50">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Gender</mat-label>
          <mat-select required [(value)]="newClient.gender">
            <mat-option>Select Gender</mat-option>
            <mat-option value="male">Male</mat-option>
            <mat-option value="female">Female</mat-option>
            <mat-option value="not specified">Not Specified</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w50">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Date of Birth</mat-label>
          <input required matInput [matDatepicker]="picker" name="dob" [(ngModel)]="newClient.dob">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="p-1 font-bold">Contact Details</div>

    <div class="app-grid">
      <div class="w50">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Mobile number</mat-label>
          <ngx-mat-intl-tel-input
            style="outline: none"
            matInput
            [(ngModel)]="newClient.mobileNo"
            [preferredCountries]="['ae','in']"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            required
            name="phone"></ngx-mat-intl-tel-input>
          <mat-hint>Verify mobile no is correct, as appointments are sent to this.</mat-hint>
        </mat-form-field>
      </div>

      <div class="w50">
        <mat-form-field appearance="outline" class="w100 p-1">
          <mat-label>Email</mat-label>
          <input required matInput placeholder="Email" name="email" [(ngModel)]="newClient.email">
          <mat-icon matSuffix>email</mat-icon>
          <mat-hint>Reminders will be sent to this Email ID</mat-hint>
        </mat-form-field>
      </div>

    </div>

    <div>
      <mat-form-field appearance="outline" class="w100 p-1">
        <mat-label>Address</mat-label>
        <textarea rows="1" matInput name="address" [(ngModel)]="newClient.address"></textarea>
        <mat-hint>Optional</mat-hint>
      </mat-form-field>
    </div>


  </form>


  <!--{{newClient | json}}-->

</div>

<div mat-dialog-actions>
  <div class="flex flex-row-reverse w100">
    <button mat-flat-button color="primary"  (click)="confirm()">CREATE</button>
    <button mat-button (click)="onNoClick(false)">CANCEL</button>
  </div>
</div>
